@use "styles/variables" as *;
@use "styles/mixins" as m;

.EledoModalWrap {
  position: relative;
  opacity: 0;
  z-index: $modals-z-index;
  width: 100vw;
  //height: 100vh;
  height: 0;
  @include m.transition(height 1s linear, opacity 1s ease-in-out);
  overflow: hidden;

  .EledoModal {
    overflow: hidden;
    height: 0;
    flex-direction: column;
    max-height: 80vh;
    opacity: 0;
    @include m.transform(translate3d(0px, 50px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg));
    @include m.transition(transform 1s ease-in-out, opacity 1s ease-in-out, height 1s linear);
    transform-style: preserve-3d;

    @media screen and (max-width: 500px) {
      min-width: 100% !important;
      width: 100% !important;
    }

    .ModalContent {
      overflow: visible;
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: none; /* Firefox 64 */
    }
  }
}

.Overlay {
  overflow: visible;
  enable-background: new 0 0 158.6 158.6;
}

.EledoModalOpen {
  height: auto !important;
  @include m.display-flex-important;
  opacity: 1 !important;
  @include m.transform(translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg));
}

.EledoModalWrapOpen {
  height: 100vh;
  @include m.display-flex-important;
  opacity: 1;
}

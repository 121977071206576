@use 'styles/variables' as *;
@use 'styles/mixins' as mixin;

.eledoPdfToolbar {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 300;
  @include mixin.display-flex;
  @include mixin.flex-direction-row;
  background-color: $primary-white;
  padding: 0.5rem 1rem;

  .toolbarGroup {
    @include mixin.display-flex;
    @include mixin.flex-direction-row;
  }
}
@use "styles/variables" as *;
@use "styles/mixins" as m;

.ListCard {
  min-height: 25rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: whitesmoke;
  background-color: white;
  background-position-y: 1.5rem;
  position: relative;

  .Overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0.8));
    color: white;
    z-index: 5;
  }

  h3 {
    position: absolute;
    bottom: 0;
    margin: 1rem 1.5rem;
  }
}

.DeleteModal {
  display: none;
  opacity: 0;
  z-index: $modals-z-index;
}

.LogsModal {
  @include m.transform(translate3d(0px, 80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg));
  transform-style: preserve-3d;
  opacity: 0;
}

.Overlay {
  overflow: visible;
  enable-background: new 0 0 158.6 158.6;
}

.DeleteModalOpen {
  @include m.display-flex;
  opacity: 1;
}

.LogsModalOpen {
  opacity: 1;
}

.ThumbnailPlaceholderContainer {
  background: white;
  @include m.display-flex;
  @include m.flex-direction-column;
  @include m.flex-align-center;
}

.ThumbnailPlaceholder {
  margin-top: 1rem;
  height: 70%;
}

.EditButton {
  .EditIcon, .EditTooltip {
    display: initial;
  }
  .EditIconLabel {
    display: none;
  }
}

@media screen and (max-width: $screen-size-l-max) {
  .EditButton {
    @include m.icon-button;
    @include m.rectangular-button;
    white-space: nowrap;
  }
  .EditIcon, .EditTooltip {
    display: none;
  }
  .EditIconLabel {
    display: inline-block;
  }
}

.tileMenuOpen {
  @include m.transform(translate(0px, 0px));
  transition-duration: 0.3s;
}

.tileMenuClosed {
  @include m.transform(translate(0px, 115px));
  transition-duration: 0.3s;
}
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?xi1587');
  src:  url('fonts/icomoon.eot?xi1587#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?xi1587') format('truetype'),
    url('fonts/icomoon.woff?xi1587') format('woff'),
    url('fonts/icomoon.svg?xi1587#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-thumbs-down:before {
  content: "\e900";
}
.icon-thumbs-up:before {
  content: "\e901";
}
.icon-image:before {
  content: "\e90d";
}
.icon-book:before {
  content: "\e91f";
}
.icon-books:before {
  content: "\e920";
}
.icon-library:before {
  content: "\e921";
}
.icon-search:before {
  content: "\e986";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-quill1:before {
  content: "\e908";
}
.icon-lifebuoy1:before {
  content: "\e942";
}
.icon-floppy-disk:before {
  content: "\e962";
}
.icon-user1:before {
  content: "\e972";
}
.icon-key:before {
  content: "\e98d";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-cog:before {
  content: "\e994";
}
.icon-bin1:before {
  content: "\e9ad";
}
.icon-list:before {
  content: "\e9ba";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-tree:before {
  content: "\e9bc";
}
.icon-contrast:before {
  content: "\e9d5";
}
.icon-question:before {
  content: "\ea09";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-upload2:before {
  content: "\e9c6";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-infinite:before {
  content: "\ea2f";
}
.icon-bug:before {
  content: "\e999";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-undo2:before {
  content: "\e967";
}
.icon-redo2:before {
  content: "\e968";
}
.icon-spinner2:before {
  content: "\e97b";
}
.icon-pencil:before {
  content: "\e905";
}
.icon-eyedropper:before {
  content: "\e90a";
}
.icon-droplet:before {
  content: "\e90b";
}
.icon-credit-card:before {
  content: "\e93f";
}
.icon-rocket:before {
  content: "\e9a5";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-enter:before {
  content: "\ea13";
}
.icon-font:before {
  content: "\ea5c";
}
.icon-text-color:before {
  content: "\ea6d";
}
.icon-file-text:before {
  content: "\e922";
}
.icon-file-empty:before {
  content: "\e924";
}
.icon-files-empty:before {
  content: "\e925";
}
.icon-file-text1:before {
  content: "\e926";
}
.icon-copy:before {
  content: "\e92c";
}
.icon-paste:before {
  content: "\e92d";
}
.icon-stack:before {
  content: "\e92e";
}
.icon-lifebuoy:before {
  content: "\e941";
}
.icon-user:before {
  content: "\e971";
}
.icon-pie-chart:before {
  content: "\e99a";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-exit:before {
  content: "\ea14";
}
.icon-paragraph-justify:before {
  content: "\ea7a";
}
.icon-facebook:before {
  content: "\ea91";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-paypal:before {
  content: "\ead8";
}
.icon-file-pdf:before {
  content: "\eadf";
}

body > iframe[style*="2147483647"] {
  max-height: 2px;
  background: rgba(255, 0, 0, 0.2);
}

/*

obcas ked mam hotreload lebo som savol subor, tak mi napise error s process
to vraj je bug v starom reacte, ale staci udajne aj upgradnut verziu react-error-overlay
vid tu:
  https://stackoverflow.com/questions/70368760/react-uncaught-referenceerror-process-is-not-defined
avsak nechcem riskovat take velke zasahy
takze safe riesenie aby to pri vyvoji neblokovalo kompletne ui
je ze v css to prebijem aby to nezavadzalo


*/
 
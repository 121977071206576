@use "styles/mixins" as m;
@use "styles/variables" as *;

.EledoHelpdesk {
  .media {
    margin-left: 40px;
  }

  .section {
    overflow: hidden;
    //@include m.transition(all 0.25s ease-out);
    height: auto;
    flex: 1;

    //&.collapsed {
    //  flex: 0;
    //}
  }
}

.ButtonIcon {
  margin-right: 0.5rem;
  margin-left: -0.5rem;
}

.container {
  @include m.display-flex;
  @include m.flex-direction-column;
}

.MessageList {
  .LeftGrid {
    -ms-grid-columns: 0.1fr 1fr 0.2fr;
    grid-template-columns: 0.1fr 1fr 0.2fr;
  }
  .RightGrid {
    -ms-grid-columns: 0.2fr 1fr 0.1fr;
    grid-template-columns: 0.2fr 1fr 0.1fr;
  }

  .Icon {
    @include m.display-flex;
    @include m.flex-align-start;
    margin: 1rem 0;

    &.IconRight {
      justify-content: start;
    }
    &.IconLeft {
      justify-content: end;
    }

    i {
      font-size: 2rem;
    }
  }
  .Text {
    white-space: pre-wrap;      /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -o-pre-wrap;   /* Opera 7 */
    word-wrap: anywhere;      /* IE */
  }
  .Date {

  }
}
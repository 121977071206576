.EledoPropertyList {
  th {
    text-align: start;
  }

  tr {
    padding-bottom: 0.5rem;
  }

  td, th {
    padding-right: 0.5rem;
    &:last-child {
      padding-right: 0;
    }
  }

  .addRowButton {
    margin-top: 1rem;
  }
}

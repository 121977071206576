@use 'styles/variables' as *;
@use "styles/mixins" as m;

$text-bold-on-hover: 0 0 0.5px $primary-green-active;

.EledoTabs {
  .tabList {
    padding: 0;
    margin-bottom: 0;
    box-shadow: $shadow-tabs;
    border-bottom: 1px solid $accent-gray;
  }

  .tabContent {
    padding-top: 2rem;
    position: relative;

    .contentCard {
      width: 100%;
      visibility: hidden;
      @include m.transition(all 150ms ease-in-out);
      position: absolute;
      padding-right: 4rem;
    }

    .activeTabContent {
      transform: translateX(0%);
      visibility: visible;
    }

    .slideRight {
      @include m.transform(translateX(200%));
    }

    .slideLeft {
      @include m.transform(translate(-200%));
    }
  }

  .activeTab {
    text-shadow: $text-bold-on-hover;

    &:after {
      @include m.transform(scaleX(1) !important);
    }
  }
}

.tabListItem {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 1rem 1.5rem 0 0;
  text-align: center;

  .tabLink {
    display: inline-block;
    margin: 0 0.5rem;
    padding: 5px 5px 0;

    @include m.transition(border $transition-tabs);
    color: $primary-green-active;
    font-size: 17px;
    text-decoration: none;
    width: 120%;
    text-align: center;
    cursor: pointer;

    &.white {
      color: $primary-white;
    }
  }

  .tabLink:after {
    display: block;
    content: '';
    border-bottom: solid 3px $primary-green-active;
    @include m.transform(scaleX(0));
    @include m.transition(transform $transition-tabs);
    transform-origin: center;
    margin-top: 0.3rem;
  }

  .tabLink {
    &:hover {
      text-shadow: $text-bold-on-hover;
    }
    &:hover:after {
      @include m.transform(scaleX(1) !important);
    }
    &.white:hover:after {
      border-color: $primary-white;
    }
  }
}

@use "styles/variables" as *;
@use "styles/mixins" as m;


.logDetail {
  @include m.display-flex;
  @include m.flex-direction-row;
  cursor: pointer;

  i {
    margin-right: 0.5rem;
  }

  &>div {
    @include m.display-flex;
    @include m.flex-direction-column;

    label {
      margin-bottom: 0;
      white-space: nowrap;
    }

    div {
      margin-bottom: 1rem;
      white-space: break-spaces;
    }

    &>:last-child {
      margin-bottom: 0;
    }
  }
}

.logMessages {
  .messageGrid {
    -ms-grid-columns: .1fr 1fr;
    grid-template-columns: .1fr 1fr;

    .tileTitle {
      display: none;
    }
  }
}

@media screen and (max-width: calc(#{$screen-size-s-max} + 150px)) {
  .logMessages {
    .messageGrid {
      -ms-grid-columns: .1fr 1fr;
      grid-template-columns: .1fr 1fr;

      .tileTitle {
        display: block !important;
      }
    }
  }
}
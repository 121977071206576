.EledoSecurity {
  &.section-small {
    padding-top: 0 !important;
  }
}

.checkbox {
  &:not(:last-of-type) {
    margin-bottom: 0 !important;
  }
}

@use 'styles/variables' as *;
@use "styles/mixins" as m;

.Active {
  background-color: $focus-color-lighter-60 !important;
}

.navbarLeft {
  width: $navbar-left-width-collapsed;
  border-radius: 0 4px 4px 0;

  background-color: $primary-green;
  color: white !important;

  @include m.display-flex;
  @include m.flex-justify-start;
  @include m.flex-column-wrap;

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  border: none;
  overflow: hidden;

  padding-left: 5px;
  padding-right: 5px;

  box-shadow: none;

  @include m.transition(0.25s $transition-navbar);
  z-index: $left-menu-z-index !important;

  &:hover {
    overflow: visible;
  }

  li {
    white-space: nowrap;
    text-indent: 5px !important;
    position: relative;

    a {
      color: white !important;
      white-space: nowrap;
    }
  }

  .navbarHeader {
    //float: left;
    position: relative;

    a {
      padding-right: 0;
    }

    .logo {
      margin: 1rem 0.1rem;
      white-space: nowrap;
    }
  }

  .navbarBody {
    .subMenu {
      top: -29px;
      bottom: unset !important;
      left: 97%;
      cursor: pointer;
    }
  }

  .navbarFooter {
    justify-self: flex-end;
    bottom: 1.5rem;
    position: absolute;
    padding-right: 0;
    margin-right: 0;
    width: calc(100% - 10px);
    left: 0;

    .navList {
      position: relative;
    }

    ul {
      margin: 5px;
    }

    .subMenu {
      bottom: -29px;
      top: unset !important;
      left: 95%;
    }
  }

  ul {
    width: 100%;
    list-style-type: none;
    padding-left: 0;
  }

  .subMenu {
    background-color: white;
    @extend .navList;
    position: absolute !important;
    padding: 16px 8px !important;
    @include m.transform(scale(0.9));
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    width: 0;
    overflow: hidden;
    @include m.transition(all 0.25s $transition-navbar);
    box-shadow: $shadow-nav;

    i, span {
      color: black;
    }

    .navLink {
      padding: 0.7rem 0.7rem;
      border-color: transparent !important;
      border-width: 0 !important;
      cursor: pointer;

      &:focus, &:hover {
        background-color: $accent-snow !important;
        border-color: transparent !important;
        border-width: 0 !important;
      }

      &.navLinkActive {
        background-color: darken($accent-snow, 20%) !important;
        border-color: transparent !important;
        border-width: 0 !important;
      }
    }
  }

  i {
    line-height: 1.2em;
  }

  .badge {
    margin-left: auto;
    padding: 0.35em 0.65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: white;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: #e43d00;
    text-indent: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }

  .submenuIcon {
    float: right;
    margin-right: 0.7rem;
    margin-top: 5px;
    vertical-align: middle;
    font-size: 0.9rem;
    font-weight: bold;
    @include m.transition-delay(0.5s);
    line-height: 0.9em;
  }

  .renderTemplateStyle {
    display: inline-block;
    padding-top: 3px;
    margin-right: -6px;
  }

  .renderTemplateStyle, .submenuIcon {
    @include m.transition(opacity 0.25s $transition-navbar, visibility 0.25s $transition-navbar);
    visibility: hidden;
    opacity: 0;
  }

  .navList {
    list-style-type: none;
    padding-left: 0;
  }

  li:active, li:focus, li:hover {
    .subMenu {
      opacity: 1;
      visibility: visible;
      width: 100%;
    }
  }

  .navItem {
    width: 100%;
    color: white;
    padding-top: 2px;
    display: inline-flex;
    cursor: pointer;
  }

  .navLink {
    padding: 0.5rem 0.7rem;
    border-radius: 4px;
    background-color: transparent;
    @include m.transition(background-color 0.25s $transition-navbar);
    text-decoration: none !important;
    margin: 2px 4px;

    &:focus, &:hover {
      background-color: $focus-color-lighter-40;
      color: white !important;
    }

    &:active {
      @extend .Active;
    }
  }

  .navLabel {
    margin-left: 1rem;
    margin-right: 0.5rem;
    display: initial;
    background-color: transparent;
  }

  .logoLabel {
    margin-left: 0;
  }

  .navLinkActive {
    @extend .Active;
  }
}

.leftMenuDisplayAlways {
  width: $navbar-left-width-uncollapsed;
  .submenuIcon, .renderTemplateStyle {
    visibility: visible;
    opacity: 1;
  }
}

.leftMenuDisplayOnHover.navbarLeft:focus, .leftMenuDisplayOnHover.navbarLeft:hover {
  width: $navbar-left-width-uncollapsed;
  box-shadow: $shadow-nav-left;
  .submenuIcon, .renderTemplateStyle {
    visibility: visible;
    opacity: 1;
  }
}

@mixin displayOnHover {
  visibility: hidden;
  opacity: 0;

  @include m.transition(visibility 0.25s, opacity 0.25s $transition-navbar);
}

@mixin displayOnHover_navbarLeft {
  visibility: visible;
  opacity: 1;

  @include m.transition(visibility 0s, opacity 0.25s $transition-navbar);
}

.leftMenuDisplayOnHover .navLabel,
.leftMenuDisplayOnHover .displayOnHover {
  @include displayOnHover;
}

.leftMenuDisplayOnHover.navbarLeft:hover .navLabel,
.leftMenuDisplayOnHover.navbarLeft:focus .navLabel,
.leftMenuDisplayOnHover.navbarLeft:hover .displayOnHover,
.leftMenuDisplayOnHover.navbarLeft:focus .displayOnHover {
  @include displayOnHover_navbarLeft;
}

.leftMenuDisplayAlways .displayOnHover {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: $screen-size-xs-max) {
  .leftMenuDisplayAlways {
    width: $navbar-left-width-collapsed;
    .submenuIcon, .renderTemplateStyle {
      visibility: hidden;
      opacity: 0;
    }
  }

  .leftMenuDisplayAlways.navbarLeft:focus, .leftMenuDisplayAlways.navbarLeft:hover {
    width: $navbar-left-width-uncollapsed;
    box-shadow: $shadow-nav-left;
    .submenuIcon, .renderTemplateStyle {
      visibility: visible;
      opacity: 1;
    }
  }

  .leftMenuDisplayAlways .navLabel,
  .leftMenuDisplayAlways .displayOnHover {
    @include displayOnHover;
  }

  .leftMenuDisplayAlways.navbarLeft:hover .navLabel,
  .leftMenuDisplayAlways.navbarLeft:focus .navLabel,
  .leftMenuDisplayAlways.navbarLeft:hover .displayOnHover,
  .leftMenuDisplayAlways.navbarLeft:focus .displayOnHover {
    @include displayOnHover_navbarLeft;
  }

  .leftMenuDisplayAlways .displayOnHover {
    visibility: visible;
    opacity: 1;
  }
}
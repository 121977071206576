@use 'styles/variables' as *;
@use 'styles/mixins' as m;


.itemWrapper {
  
  label {
    @include m.heading(1rem, 1.1rem, 500);
  }

  input {
    width: 300px;
  }
}
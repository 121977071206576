@use 'styles/variables' as *;

.resizableRectangle {
  position: absolute;
  border: 1px solid $accent-red;
  z-index: 200;
  cursor: move;
  &.dragging {
    z-index: 10;
    cursor: move;
  }
  &.ImageURL {
    background-image: url('../assets/ImageURL_placeholder.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #D0D0D0;
  }
  &.BC1_EAN_8, &.BC1_EAN_13, &.BC1_CODE_39, &.BC1_CODE_93, &.BC1_CODE_128, &.BC2_AZTEC, &.BC2_DATA_MATRIX, &.BC2_PDF417, &.BC2_QR_CODE {
    background-position: calc(100% / 2) center;
    background-color: #fff;
    image-rendering: pixelated;
  }
  &.BC1_EAN_8 {
    &.zoom1 {
      background-image: url('https://eledo.online/assets/ck/b3e33b67/ckeditor/plugins/dgcomponents/styles/ean81.png');
    }
    &.zoom2 {
      background-image: url('https://eledo.online/assets/ck/b3e33b67/ckeditor/plugins/dgcomponents/styles/ean82.png');
    }
    &.zoom3 {
      background-image: url('https://eledo.online/assets/ck/b3e33b67/ckeditor/plugins/dgcomponents/styles/ean83.png');
    }
  }
  &.BC1_EAN_13 {
    &.zoom1 {
      background-image: url('https://eledo.online/assets/ck/173f62f7/ckeditor/plugins/dgcomponents/styles/ean131.png');
    }
    &.zoom2 {
      background-image: url('https://eledo.online/assets/ck/173f62f7/ckeditor/plugins/dgcomponents/styles/ean132.png');
    }
    &.zoom3 {
      background-image: url('https://eledo.online/assets/ck/173f62f7/ckeditor/plugins/dgcomponents/styles/ean133.png');
    }
  }
  &.BC1_CODE_39 {
    &.zoom1 {
      background-image: url('https://eledo.online/assets/ck/a4c96260/ckeditor/plugins/dgcomponents/styles/code391.png');
    }
    &.zoom2 {
      background-image: url('https://eledo.online/assets/ck/a4c96260/ckeditor/plugins/dgcomponents/styles/code392.png');
    }
    &.zoom3 {
      background-image: url('https://eledo.online/assets/ck/a4c96260/ckeditor/plugins/dgcomponents/styles/code393.png');
    }
  }
  &.BC1_CODE_93 {
    &.zoom1 {
      background-image: url('https://eledo.online/assets/ck/f55a8748/ckeditor/plugins/dgcomponents/styles/code931.png');
    }
    &.zoom2 {
      background-image: url('https://eledo.online/assets/ck/f55a8748/ckeditor/plugins/dgcomponents/styles/code932.png');
    }
    &.zoom3 {
      background-image: url('https://eledo.online/assets/ck/f55a8748/ckeditor/plugins/dgcomponents/styles/code933.png');
    }
  }
  &.BC1_CODE_128 {
    &.zoom1 {
      background-image: url('https://eledo.online/assets/ck/8abc5a54/ckeditor/plugins/dgcomponents/styles/code1281.png');
    }
    &.zoom2 {
      background-image: url('https://eledo.online/assets/ck/8abc5a54/ckeditor/plugins/dgcomponents/styles/code1282.png');
    }
    &.zoom3 {
      background-image: url('https://eledo.online/assets/ck/8abc5a54/ckeditor/plugins/dgcomponents/styles/code1283.png');
    }
  }
  &.BC2_AZTEC {
    &.zoom1 {
      background-image: url('https://eledo.online/assets/ck/588f60bc/ckeditor/plugins/dgcomponents/styles/aztec1.png');
    }
    &.zoom2 {
      background-image: url('https://eledo.online/assets/ck/31745da9/ckeditor/plugins/dgcomponents/styles/aztec2.png');
    }
    &.zoom3 {
      background-image: url('https://eledo.online/assets/ck/26890c23/ckeditor/plugins/dgcomponents/styles/aztec3.png');
    }
  }
  &.BC2_DATA_MATRIX {
    &.zoom1 {
      background-image: url('https://eledo.online/assets/ck/4949c361/ckeditor/plugins/dgcomponents/styles/datamatrix1.png');
    }
    &.zoom2 {
      background-image: url('https://eledo.online/assets/ck/ea05557b/ckeditor/plugins/dgcomponents/styles/datamatrix2.png');
    }
    &.zoom4 {
      background-image: url('https://eledo.online/assets/ck/61d3ee12/ckeditor/plugins/dgcomponents/styles/datamatrix4.png');
    }
  }
  &.BC2_PDF417 {
    &.zoom1 {
      background-image: url('https://eledo.online/assets/ck/bfc9d80f/ckeditor/plugins/dgcomponents/styles/pdf4171.png');
    }
    &.zoom2 {
      background-image: url('https://eledo.online/assets/ck/acaf6007/ckeditor/plugins/dgcomponents/styles/pdf4172.png');
    }
    &.zoom3 {
      background-image: url('https://eledo.online/assets/ck/76a76725/ckeditor/plugins/dgcomponents/styles/pdf4173.png');
    }
  }
  &.BC2_QR_CODE {
    &.zoom1 {
      background-image: url('https://eledo.online/assets/ck/7ef32569/ckeditor/plugins/dgcomponents/styles/qrcode1.png');
    }
    &.zoom2 {
      background-image: url('https://eledo.online/assets/ck/2c02a73f/ckeditor/plugins/dgcomponents/styles/qrcode2.png');
    }
    &.zoom3 {
      background-image: url('https://eledo.online/assets/ck/f14e286a/ckeditor/plugins/dgcomponents/styles/qrcode3.png');
    }
  }

  .square {
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: white;
    border: 1px solid $accent-red;
    border-radius: 3px;
    z-index: 110;
    &.noresize {
      border: none;
      background: transparent;
    }
  }

  .resizableHandler {
    position: absolute;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    z-index: 111;
    display: block;

    &.topLeft,
    &.top,
    &.topRight {
      top: -8px;
    }

    &.topLeft,
    &.left,
    &.bottomLeft {
      left: -8px;
    }

    &.bottomLeft,
    &.bottom,
    &.bottomRight {
      bottom: -8px;
    }

    &.bottomRight,
    &.right,
    &.topRight {
      right: -8px;
    }

    &.left,
    &.right {
      margin-top: -8px;
    }

    &.top,
    &.bottom {
      margin-left: -8px;
    }

    &.hidden {
      display: none;
    }
  }

  .rotate {
    position: absolute;
    left: 50%;
    top: -26px;
    width: 18px;
    height: 18px;
    margin-left: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .top,
  .topLeft,
  .topRight {
    top: -8px;
  }

  .bottom,
  .bottomLeft,
  .bottomRight {
    bottom: -8px;
  }

  .right,
  .topRight,
  .bottomRight {
    right: -8px;
  }

  .topLeft,
  .left,
  .bottomLeft {
    left: -8px;
  }

  .left,
  .right {
    top: 50%;
    margin-top: -8px;
  }

  .top,
  .bottom {
    left: 50%;
    margin-left: -8px;
  }

  &.hidden {
    display: none;
  }
}
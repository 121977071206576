

div.FedAutocomplete {
  padding-inline-start: 0;

  li {
    padding: 3px 8px;
    white-space: nowrap;
    overflow-x: hidden;
  }

  li.active {
    background: #eef !important;
  }
  div.item {
    display: flex;
    flex-direction: row;
  }
  div.fragment {
    width: 24px;
    text-align: center;
    flex-shrink: 0;
  }
  div.title {
    flex-grow: 1;
  }
  div.title:hover {
    background: #ddf !important;
    cursor: pointer;
  }
  div.type {
//    vertical-align: top;
  }

  li.identType-Fn {
    color: green;
  }
  li.identType-Object {
    color: black;
    background: #ffe;
  }
  li.identType-Array {
    color: black;
  }
  li.identType-Number {
    color: black;
  }
  li.identType-String {
    color: black;
  }
  li.identType-Bool {
    color: black;
  }
  li.identType-Date {
    color: black;
  }
  li.identType-Boolean {
    color: black;
  }

  li.identType {
    opacity: 70%;
  }

  div.item.doesntFit {
    color: #aaa !important;
    background: #fafafa;
  }


  /* sorry, nejako mi nesli tieto cez module kvoli css kombinaciam */
  
  .doExpand,
  .doCollapse {
      display: none;
      color: black;
      width: 0px;
  }
  .doExpand span,
  .doCollapse span {
      position: relative;
      left: -18px;
  }   

  li.identType-Object:not(.objectExpanded) .doExpand {
    display: inline-block;
    cursor: zoom-in;
  }
  li.identType-Object.objectExpanded .doCollapse {
    display: inline-block;
    cursor: zoom-out;
  }

}

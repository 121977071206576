@use "styles/mixins" as m;

.EledoEditor {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 100%;
}

.DocumentLoader {
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.document-editor, .editor-container, .document-editor__editable-container :nth-child(0) {
  @include m.display-flex-important;
  @include m.flex-direction-column;
  @include m.flex-auto;
  @include m.flex-align-stretch;
}

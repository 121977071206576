@use 'styles/variables' as *;
@use "styles/mixins" as m;

.EledoDocumentPaginator {
  padding: 0.25rem 0;
  @include m.display-flex;

  .paginationContainer {
    @include m.display-flex;
    @include m.flex-direction-row;
    @include m.flex-align-baseline;
  }

  .pagination {
    margin: 0.2rem 0 0 0;
    display: inline-flex;

    .number {
      //width: 35px;
      //height: 37px;
      width: 1.8rem;
      height: 1.9rem;
      padding-top: 3px;
      padding-bottom: 1px;
      border-color: $accent-gray-lighter-20;
      border-width: 1px;

      &.currentPage {
        cursor: pointer;
      }

      &[aria-disabled] {
        color: $accent-gray-lighter-20;
        cursor: default !important;
        &:hover {
           background-color: white;
        }
      }
    }

    a {
      color: $primary-green-active;
      text-decoration: none;
    }

    i::before { margin-left: 5px; }
  }

  @media screen and (max-width: 991px) {
    padding-top: 0;
  }

  white-space: break-spaces;
  i {
    margin-right: auto;
  }

  .activePage {
    margin: 0 0.5rem;
  }

  .addLink, .deleteLink {
    padding-left: 1.5rem;
    white-space: nowrap;

    i {
      padding-right: 0;
      margin-right: 4px;
    }

    &:hover {
      text-decoration: none !important;
    }

    &[aria-disabled] {
      color: $accent-gray-lighter-25 !important;
      cursor: default !important;
    }
  }

  .selectionContainer {
    @include m.display-flex;
    @include m.flex-direction-row;
    @include m.flex-wrap;
    row-gap: 0.2rem;
    column-gap: 0.3rem;
    border-radius: 4px;

    .number {
      border: none;
      background-color: $primary-text;
      border-radius: 4px;
      color: white;

      &:hover {
        color: $primary-text !important;
        font-weight: bold;
        background-color: $primary-white;
      }
      &[aria-disabled] {
        background-color: $primary-white;
        color: $primary-text;
        font-weight: bold;
      }
    }
  }

  .selectionTooltip {
    padding: 0.25rem 0.3rem 0.3rem 0.2rem;
    margin: 0;
    border-radius: 4px;
  }
}

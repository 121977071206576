@use 'styles/variables' as *;
@use "styles/mixins" as m;

.progressVisualFull {
  @include m.display-flex;
  height: 0.4rem;
  margin: 20px 0;

  .progressVisualPart {
    width: 0;
    background-color: $primary-green;
    @include m.transition(all 1s $transition-bezier);
    @include m.transition-delay(0.5s);
  }
}

.progressLabel {
  font-size: 0.9rem;
  margin-bottom: -0.5rem;
}

:export {
  accentShow: $accent-snow;
  progressBgColor: $accent-gray-lighter-25;
}
@use 'styles/variables' as *;
@use "styles/mixins" as m;

.EledoTemplateTopToolbar {
  @include m.display-flex;
  @include m.flex-direction-row;
  @include m.flex-justify-space-between;
  @include m.flex-align-center;
  box-shadow: $shadow-tabs;
  padding: 1.5rem !important;
  padding-bottom: 0 !important;
  z-index: $tabs-z-index;

  button {
    margin-bottom: 0;
  }

  @media screen and (max-width: $screen-size-xs-max) {
    @include m.flex-direction-column;
    @include m.flex-justify-start;
    @include m.flex-align-start;
  }

  .titleContainer {
    max-width: 50%;
    align-self: baseline;
    @media screen and (max-width: $screen-size-s-max) {
      max-width: 100%;
    }
  }

  .templateTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.5em;
    max-height: 4em;

    @media screen and (max-width: $screen-size-s-max) {
      display: block;
      -webkit-box-orient: horizontal;
      -webkit-line-clamp: 1;
      line-height: 1em;
      max-height: 2em;
      white-space: nowrap;
      margin-bottom: 1rem;
    }
  }

  .versionAndButtonContainer {
    @include m.display-flex;
    @include m.flex-direction-row;
    @include m.flex-align-center;

    @media screen and (max-width: $screen-size-m-max) {
      @include m.flex-align-start;

      .EledoTemplateVersion {
        align-self: start;
      }
    }

    @media screen and (max-width: $screen-size-xs-max) {
      @include m.display-flex;
      @include m.flex-direction-row;
      @include m.flex-align-start;
      @include m.flex-justify-space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .buttonGroup {
      @include m.display-flex;
      @include m.flex-direction-row;
      @include m.flex-align-center;

      @media screen and (max-width: $screen-size-m-max) {
        @include m.flex-wrap;

        button {
          line-height: 0.95em;
          margin-right: 5px !important;
          margin-bottom: 5px !important;
          padding: 10px 16px !important;
        }
      }
    }

    @media screen and (max-width: $screen-size-xs-max) {
      @include m.flex-direction-row;

      .buttonGroup {
        margin-top: 0.5rem;
      }
    }
  }

  i, h3 {
    margin-right: 0.75rem;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .closeButton {
    margin-right: 0;
  }

  .EledoTemplateVersion {
    @include m.display-flex;
    @include m.flex-direction-column;
    @include m.flex-align-self-center;
    margin-right: 1rem;
    margin-left: auto;
    white-space: nowrap !important;

    .rollbackIcon { margin-right: 0.25rem; }
  }
}

.topToolbarShadow {
  box-shadow: $shadow-tabs;
  padding-bottom: 1rem !important;
  border-bottom: 1px solid $accent-gray;
}

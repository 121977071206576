@use 'styles/variables' as *;
@use "styles/mixins" as m;

.EledoStyle {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: relative;
  overflow: hidden;

  form {
    //max-height: 100%;
    //overflow: scroll;
    //display: inherit;

    @include m.display-flex;
    position: relative;
    overflow: initial;
    height: 100%;
    max-height: 100%;

    textarea {
      resize: vertical;
      overflow: auto;
      min-height: 20rem;

      &.invalid {
        background-color: $accent-red-light;
      }
    }
  }
}

.DocumentLoader {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  display: table;
}

.StyleEditor {
  min-height: 10rem;
}

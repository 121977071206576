@use "styles/variables" as *;
@use "styles/mixins" as m;

.EledoExpressionFieldNew {
  .Container {
    position: relative;
  }

  .ExpSuggestions {
    width: 100%;
    background-color: $primary-white;
    box-shadow: $mat-shadow-3;
    min-height: 50px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: $dropdown-z-index;
    top: 2.5rem;
    left: 0;
    //border-top: 1px solid lightgray;
    border: 1px solid $primary-green-active;

    .ExpDropdownGroup {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .ExpSuggestionLabel, .ExpSuggestionItem {
      cursor: default;
      width: 100%;
      padding: 3px 5px 3px 1rem;
      min-width: 400px;
    }

    .ExpSuggestionLabel {
      font-size: small;
      font-weight: bold;
    }

    .ExpSuggestionItem {
      cursor: pointer;
      line-height: 2rem;
      padding-left: 2rem;
      padding-top: 5px;

      &.Selected {
        color: white;
        background-color: $primary-text; //$primary-green-active;
      }
    }
  }

  .ExpressionInput {
    padding-left: 1.8rem;
  }

  .ExpNotification {
    width: 1.3rem;
    height: 1.3rem;
    position: absolute;
    top: 10px;
    left: 7px;
    cursor: help;

    span {
      display: none;
      color: #fff;
      width: 1.1rem;
      height: 1.1rem;
      line-height: 1.25rem;
      border-radius: 50%;
      font-size: 0.65rem;
      @include m.text-align(center);
    }

    .Hint, .Ok, .Error {
      color: white;
      display: block;
    }

    .Hint {
      background-color: transparent;
      color: $primary-text;
      font-size: 1rem;
      font-weight: bold;
      display: block;
    }
    .Ok {
       background-color: darken($primary-green, 20%);
    }
    .Error {
      background-color: $accent-red;
    }
  }
}
@use 'styles/variables' as *;
@use "styles/mixins" as m;

.EledoPricingChange {
  .RadioGroup {
    padding: 1rem 1rem 0.5rem 1rem;
  }
}

.Payments {
  @include m.display-flex;
  @include m.flex-direction-column;

  .PayPalPoweredBy {
    @include m.display-flex;
    @include m.flex-direction-row;
    @include m.flex-align-end;
    @include m.flex-justify-center;
    font-size: 0.6875rem;
    font-style: italic;

    img {
      @include m.transform(scale(0.7));
      margin-left: -0.5em;
    }
  }
}
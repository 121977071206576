@use 'styles/variables' as *;

.EledoPricing {
  .eleComparisonRow {
    .eleColWide {
      display: inline-block;
      text-align: left;
      width: 33%
    }

    div {
      display: inline-block;
      text-align: center;
      width: 13%
    }
  }

  .EnterprisePlan {
    padding-right: 20px;
  }

  @media screen and (max-width: $screen-size-m-max) {
    .EnterprisePlan {
      width: calc(100%) !important;
    }
  }

  .buttonPlaceholder {
    border: 2px solid transparent;
    width: 8.5rem;
    padding: 11px 25px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    margin-top: 0;
  }
}
@use 'styles/variables' as *;
@use "styles/mixins" as m;

.EledoLoader {
  @include m.display-flex;
  @include m.flex-direction-row;
  @include m.flex-align-center;

  margin: 10px 10px;
  height: 0.8rem;

  div {
    border-radius: 50%;
    display: inline-block;
    margin: 0.2rem;
    animation: scaling 1s ease-in-out infinite;
    background-color: $accent-gray-lighter-20;
  }

  @keyframes scaling {
    0%, 100% {
      @include m.transform(scale(0.2));
    }
    50% {
      @include m.transform(scale(1));
    }
  }

  div:nth-child(1) {
    animation-delay: 0s;
    height: 0.4rem;
    width: 0.4rem;
  }
  div:nth-child(2) {
    animation-delay: 0.1s;
    height: 0.3rem;
    width: 0.3rem;
  }
  div:nth-child(3) {
    animation-delay: 0.2s;
    height: 0.5rem;
    width: 0.5rem;
  }
  div:nth-child(4) {
    animation-delay: 0.3s;
    height: 0.3rem;
    width: 0.3rem;
  }
  div:nth-child(5) {
    animation-delay: 0.4s;
    height: 0.4rem;
    width: 0.4rem;
  }
}

@use 'styles/variables' as *;
@use "styles/mixins" as m;

$toggle-button-left-color: $primary-green-active;
$toggle-button-left-bgcolor: lighten($toggle-button-left-color, 80%);
$toggle-button-right-color: darken($accent-yellow, 20%);
$toggle-button-right-bgcolor: lighten($toggle-button-right-color, 50%);

.toggle-button {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  overflow: hidden;

  &.small {
    @include m.transform(scale(70%));
  }

  &.large {
    width: 74px;
    height: 36px;
  }

  &.rounded, &.rounded .layer {
    border-radius: 100px;
  }

  .knobs, .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;

    &:active + .knobs:before {
      width: 46px;
      border-radius: 100px;
    }
  }

  .knobs {
    z-index: 2;

    &:before {
      content: attr(data-unchecked);
      position: absolute;
      top: 4px;
      left: 4px;
      width: 20px;
      height: 10px;
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      padding: 9px 4px;
      background-color: $toggle-button-left-color;
      border-radius: 50%;
      @include m.transition(0.25s ease all, left 0.25s cubic-bezier(0.18, 0.89, 0.35, 1.15));
      display: inline-table !important;
    }
  }

  &.iconified {
    font-family: 'icomoon';
  }

  .layer {
    width: 100%;
    background-color: $toggle-button-left-bgcolor;
    @include m.transition(0.25s ease all);
    z-index: 1;
    opacity: 0.8;
  }

  .checkbox:checked:active + .knobs:before {
    margin-left: -26px;
  }

  .checkbox:checked + .knobs:before {
    content: attr(data-checked);
    left: calc((100% / 2) + 4px);
    background-color: $toggle-button-right-color;
  }

  .checkbox:checked ~ .layer {
    background-color: $toggle-button-right-bgcolor;
  }
}
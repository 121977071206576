@use 'styles/variables' as *;

.toolbarButton {
  background: $primary-white;
  display: inline-block;
  height: 1.6rem;
  padding: 4px 6px;
  outline: 0;
  cursor: default;
  border: 0;
  &:hover {
    box-shadow: 0 0 1px rgba(0, 0, 0, .3) inset;
    background: #ccc linear-gradient(to bottom, #f2f2f2, #ccc) no-repeat;
    -webkit-filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#', endColorstr='#cccccc');
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#', endColorstr='#cccccc');
  }
  &.disabled {
    &:hover {
      background: none;
      box-shadow: none;
    }

    .icon {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }   
  }

  .icon {
    cursor: inherit;
    background-repeat: no-repeat;
    margin-top: 1px;
    width: 16px;
    height: 16px;
    display: inline-block;
  }
}
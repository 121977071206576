@mixin heading($font-size, $line-height, $font-weight) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
}

@mixin display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin display-flex-important {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

@mixin flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

@mixin flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-column-wrap {
  flex-flow: column nowrap;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

@mixin flex-direction-column-important {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

@mixin flex-direction-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@mixin flex-direction-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-align-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@mixin flex-align-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@mixin flex-align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin flex-align-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

@mixin flex-align-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

@mixin flex-justify-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin flex-justify-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@mixin flex-justify-space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin flex-justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin flex-nowrap {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@mixin icon-button {
  padding-right: 60px;
  background-position: 129px 50%;
  background-size: 13px;
  background-repeat: no-repeat;
}

@mixin rectangular-button {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

@mixin align-self-start {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

@mixin align-self-end {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

@mixin flex-align-self-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

@mixin transition($props...){
  -webkit-transition: $props;
  -moz-transition: $props;
  -ms-transition: $props;
  -o-transition: $props;
  transition: $props;
}

@mixin transition-delay($delay) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin transform($transform...) {
  -webkit-transform: $transform;
  -moz-transition-delay: $transform;
  -ms-transform: $transform;
  transform: $transform;
}

@mixin text-align($side) {
  text-align: $side;
  text-align: #{"-moz-" + $side};
  text-align: #{"-webkit-" + $side};
}

@mixin text-align-important($side) {
  text-align: $side !important;
  text-align: #{"-moz-" + $side} !important;
  text-align: #{"-webkit-" + $side} !important;
}
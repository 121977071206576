@use 'styles/variables' as *;
@use 'styles/mixins' as m;

.editor {
  caret-color: rgba(0,0,0,0) !important;
  padding: 0 0 1rem;
  position: relative;
  &.disabled {
    user-select: none;
  }

  .document {
    margin: 1rem;
    margin-top: 3rem;

    .rectFloatingButtons {
      position: absolute;
      top: -0.5rem;
      right: -2.5rem;
      z-index: 15;
      @include m.display-flex;
      @include m.flex-direction-column;
    }   
  }
}
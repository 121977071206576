@use 'styles/variables' as *;
@use "styles/mixins" as m;

$grid-columns: 0.4fr 1fr 0.4fr 0.4fr 0.8fr 0.4fr 0.8fr;

.EledoSpending {
  .TopToolbar {
    @include m.display-flex;
    @include m.flex-direction-row;
    @include m.flex-justify-space-between;
    @include m.flex-align-end;

    .ButtonDiv {
      button {
        margin-bottom: 5px;
      }

      button:last-of-type {
        margin-right: 0;
      }
    }

    @media screen and (max-width: $screen-size-s-max) {
      @include m.flex-direction-column;
      @include m.flex-justify-start;
      @include m.flex-align-start;

      .ButtonDiv {
        @include m.align-self-end;
        padding-top: 0;
      }

      h1 {
        margin-bottom: 0;
      }

      .my-section {
        padding-bottom: 0;
      }
    }

    @media screen and (max-width: $screen-size-l-max) {
      button {
        margin-right: 5px !important;
        margin-bottom: 5px !important;
        padding: 8px 16px !important;
      }
    }
  }
  .headerGrid {
    margin-top: 0;
    @extend .dataGrid;
  }

  .dataGrid {
    -ms-grid-columns: $grid-columns;
    grid-template-columns: $grid-columns;
  }

  .Row {
    padding: 1rem 0;
    background-color: transparent;
    @include m.transition(all 0.3s ease-in-out);
    //border-bottom: 1px solid $accent-gray-lighter-25;

    .Overquota {
      div[class^="EledoProgressBar_progressVisualFull"] {
        @include m.transition(none);
        background-color: transparent !important;
      }
    }

    &:hover {
      background-color: $accent-snow;

      div[class^="EledoProgressBar_progressVisualFull"] {
        @include m.transition(background-color 0.25s $transition-bezier);
        //background-color:  $primary-white !important;
      }

      .Overquota {
        div[class^="EledoProgressBar_progressVisualFull"] {
          @include m.transition(none);
          background-color: transparent !important;
        }
      }
    }
  }

  .SpendingContainer {
    @include m.display-flex;
    @include m.flex-direction-column;
  }

  .SpendingContainer, .PaymentsContainer {
    margin-bottom: 3rem;
  }

  .SpendingTitleContainer {
    @include m.display-flex;
    @include m.flex-direction-row;
    @include m.flex-justify-space-between;
  }

  .DocumentsCreated {
    margin-bottom: 1rem;
  }

  .SpendingGrid {
    display: grid;
    -ms-grid-columns: .2fr 1fr .2fr;
    grid-template-columns: .2fr 1fr .2fr;
  }

  .noData {
    margin-top: 0.5rem;
  }
}

:export {
  primaryColor: $primary-green;
  accentYellow: $accent-yellow;
  accentRed: $accent-red;
}
@use 'styles/variables' as *;
@use 'styles/mixins' as m;


.wrapper {
  border-left: 1px solid $primary-text;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  &:first-child {
    margin-top: 1rem;
  }
  &.rootWrapper {
    border-left: none;
  }

  legend {

    label {
      @include m.heading(1.5rem, 1.7rem, 700);
    }
  }

  input {
    width: 300px;
  }
}
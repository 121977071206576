$container-max-width: 1400px;

.EledoCreateTemplate {
  .typeSwitch {
    padding-right: 0;
    padding-left: 0;
    max-width: $container-max-width - 200px;
    margin-left: auto;
    margin-right: auto;
  }
  .switchIcon {
    font-size: 5rem;
  }

  .switchTitle {
    margin-top: 10px;
  }

  .formContainer {
    max-width: $container-max-width;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .submitButton {
    margin-top: 1.5rem;
  }
}

@use "styles/mixins" as m;
@use "styles/variables" as *;

.ButtonIcon {
  margin-right: 0.5rem;
  margin-left: -0.5rem;
}

.ModalTitle {
  //font-size: 15px;
}

.Modal {
  position: absolute;
  border: 1px solid #cccccc;
  box-shadow: $shadow-helpdesk;
  height: 85vh !important;
  width: 85vw !important;

  @media screen and (max-width: 530px) {
    right: 0;
  }
}
.ModalWrap {
  background-color: rgba(234, 239, 239, 0.8);
}

.ModalActions {
  div {
    margin-top: 0 !important;
  }
}

.ButtonVisible {
  opacity: 1 !important;
}
@use 'styles/variables' as *;

.formInput {
  cursor: initial;
}

.dangerZone {
  margin-top: 2rem;
  padding: 1rem;
  background-color: $accent-snow;

  .removeButton {
    background-color: $primary-white !important;
    &:hover {
      background-color: $accent-red !important;
    }
  }
}
@use "styles/variables" as *;

.EledoConnections {
  .headerGrid {
    margin-top: 0;
    @extend .dataGrid;
  }

  .dataGrid {
    -ms-grid-columns: 1fr 0.5fr 1fr;
    grid-template-columns: 1fr 0.5fr 1fr;
    align-items: baseline;
  }

  .tileTitle {
    display: none;
  }

  @media screen and (max-width: $screen-size-s-max) {
    .headerGrid {
      display: none;
    }

    .tileTitle {
      display: block !important;
    }

    .dataGrid {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      border-bottom-color: $accent-gray;
    }
  }
}

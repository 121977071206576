
/* ===============
    TYPES
*/

/* types */
.tokenType-Bad {
  color: #fbb;
  background: #f00;
}
.tokenType-BadPartial {
  color: #000;
  background: #fcc;
}

.tokenType-Variable {
  background: yellow;
}
.tokenType-Operator {
  color: darkorchid;
}
.tokenType-Number {
  color: blue;
}
.tokenType-String {
  color: blue;
  background: #dfd;
}
.tokenType-Lparen,
.tokenType-Rparen,
.tokenType-FnStartLp {
  color: green;
  background: #cdf;
}

/* .tokenType-varier {
  color: #fbb;
  background: #f00;
} */
.tokenType-Lparen,
.tokenType-Rparen {
  color: #666;
}

/* types EXTRA */
/* .varType-Fn {
  color: blue;
  background: #cdf;
} */
.varType-Object {
  color:#000;
  background: #ff8;
}
.varType-Number {
  color: black;
  // background: #fff;
}
.varType-String {
  color: black;
  // background: #fff;
}
.varType-Bool {
  color: black;
  // background: #fff;
}
.varType-Array {
  color:black;
  // background: #fff;
}
.varType-Unknown {
  color: #c55;
  background: #fdd;
}


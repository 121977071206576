@use "styles/variables" as *;

.EledoTemplateList {
  height: 100vh;
  overflow: hidden auto;
}

.ListCardContainer {
  margin-bottom: 1.5rem;
}

.StartItem {
  display: inline-block;
  width: 144px;
  margin-right: 19px;

  .Showcase {
    height: 183px;
    border: 1px solid black;
    margin-bottom: 10px;

    .Icon {
      font-size: 3em;
    }
  }

  .Caption {
    margin: 0 4px;
    font-weight: bold;
  }
}
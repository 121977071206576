@use "styles/variables" as *;

.Plan {
  padding: 0;
  background-color: white;

  hr {
    border-top: inherit;
    border-width: 1.4px;
    border-bottom: none;

    &:first-of-type {
      margin-top: 0;
      border-top: 1px solid $accent-gray;
      border-radius: 10px 10px 0 0;
    }
  }

  .PlanTitle {
    padding: 1.5rem 0;
    margin: 0 !important;
    background-color: $accent-snow;
  }
  div, p {
    margin: 1.5rem 0;
  }

  h1, h2, h3, h4 {
    white-space: nowrap;
  }

  .PlanLine {
    *:first-child {
      margin-bottom: 0;
    }
    *:last-child {
      margin-top: 0;
    }
  }
}
.App {
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 100%;
  position: relative;
}

.Loader {
  margin-top: 100px;
}

.LoaderImage {
  position: relative;
  padding-right: 2.5rem;
  overflow: visible;
}
@use "styles/variables" as *;


.headerGrid {
  -ms-grid-columns: 1fr .2fr .2fr 1fr 1fr 1fr;
  grid-template-columns: 1fr .2fr .2fr 1fr 1fr 1fr;
  margin-top: 0;
}

@media screen and (max-width: calc(#{$screen-size-s-max} + 150px)) {
  .headerGrid {
    display: none;
  }
}

@use 'styles/variables' as *;

.page {
  position: relative;
  margin: 0 auto 2rem;
  &.addCursor {
    cursor: crosshair;
  }
  &.drawing {
    .field:hover {
      box-shadow: none;
      cursor: auto;
    }
  }

  .eventTrigger {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    &.dragging {
      cursor: move;
    } 
  }

  img {
    box-shadow: 1px 1px 4px #ebebeb;
    -moz-box-shadow: 1px 1px 4px #EBEBEB;
    -webkit-box-shadow: 1px 1px 4px #ebebeb;
    outline: none;
    border: 1px solid #CCC;
  }

  .field {
    position: absolute;
    border: solid 1px black;
    box-shadow: 0 0 3px $accent-yellow;
    overflow: hidden;
    padding: 0 0.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 150;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 5px $accent-gray-dark;
    }
    &.recDragging {
      z-index: 5;
    }
    &.hidden {
      display: none;
    }
    &.ImageURL {
      background-image: url('../assets/ImageURL_placeholder.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #D0D0D0;
    }
    &.BC1_EAN_8, &.BC1_EAN_13, &.BC1_CODE_39, &.BC1_CODE_93, &.BC1_CODE_128, &.BC2_AZTEC, &.BC2_DATA_MATRIX, &.BC2_PDF417, &.BC2_QR_CODE {
      background-position: calc(100% / 2) center;
      background-color: #fff;
      image-rendering: pixelated;
    }
    &.BC1_EAN_8 {
      &.zoom1 {
        background-image: url('https://eledo.online/assets/ck/b3e33b67/ckeditor/plugins/dgcomponents/styles/ean81.png');
      }
      &.zoom2 {
        background-image: url('https://eledo.online/assets/ck/b3e33b67/ckeditor/plugins/dgcomponents/styles/ean82.png');
      }
      &.zoom3 {
        background-image: url('https://eledo.online/assets/ck/b3e33b67/ckeditor/plugins/dgcomponents/styles/ean83.png');
      }
    }
    &.BC1_EAN_13 {
      &.zoom1 {
        background-image: url('https://eledo.online/assets/ck/173f62f7/ckeditor/plugins/dgcomponents/styles/ean131.png');
      }
      &.zoom2 {
        background-image: url('https://eledo.online/assets/ck/173f62f7/ckeditor/plugins/dgcomponents/styles/ean132.png');
      }
      &.zoom3 {
        background-image: url('https://eledo.online/assets/ck/173f62f7/ckeditor/plugins/dgcomponents/styles/ean133.png');
      }
    }
    &.BC1_CODE_39 {
      &.zoom1 {
        background-image: url('https://eledo.online/assets/ck/a4c96260/ckeditor/plugins/dgcomponents/styles/code391.png');
      }
      &.zoom2 {
        background-image: url('https://eledo.online/assets/ck/a4c96260/ckeditor/plugins/dgcomponents/styles/code392.png');
      }
      &.zoom3 {
        background-image: url('https://eledo.online/assets/ck/a4c96260/ckeditor/plugins/dgcomponents/styles/code393.png');
      }
    }
    &.BC1_CODE_93 {
      &.zoom1 {
        background-image: url('https://eledo.online/assets/ck/f55a8748/ckeditor/plugins/dgcomponents/styles/code931.png');
      }
      &.zoom2 {
        background-image: url('https://eledo.online/assets/ck/f55a8748/ckeditor/plugins/dgcomponents/styles/code932.png');
      }
      &.zoom3 {
        background-image: url('https://eledo.online/assets/ck/f55a8748/ckeditor/plugins/dgcomponents/styles/code933.png');
      }
    }
    &.BC1_CODE_128 {
      &.zoom1 {
        background-image: url('https://eledo.online/assets/ck/8abc5a54/ckeditor/plugins/dgcomponents/styles/code1281.png');
      }
      &.zoom2 {
        background-image: url('https://eledo.online/assets/ck/8abc5a54/ckeditor/plugins/dgcomponents/styles/code1282.png');
      }
      &.zoom3 {
        background-image: url('https://eledo.online/assets/ck/8abc5a54/ckeditor/plugins/dgcomponents/styles/code1283.png');
      }
    }
    &.BC2_AZTEC {
      &.zoom1 {
        background-image: url('https://eledo.online/assets/ck/588f60bc/ckeditor/plugins/dgcomponents/styles/aztec1.png');
      }
      &.zoom2 {
        background-image: url('https://eledo.online/assets/ck/31745da9/ckeditor/plugins/dgcomponents/styles/aztec2.png');
      }
      &.zoom3 {
        background-image: url('https://eledo.online/assets/ck/26890c23/ckeditor/plugins/dgcomponents/styles/aztec3.png');
      }
    }
    &.BC2_DATA_MATRIX {
      &.zoom1 {
        background-image: url('https://eledo.online/assets/ck/4949c361/ckeditor/plugins/dgcomponents/styles/datamatrix1.png');
      }
      &.zoom2 {
        background-image: url('https://eledo.online/assets/ck/ea05557b/ckeditor/plugins/dgcomponents/styles/datamatrix2.png');
      }
      &.zoom4 {
        background-image: url('https://eledo.online/assets/ck/61d3ee12/ckeditor/plugins/dgcomponents/styles/datamatrix4.png');
      }
    }
    &.BC2_PDF417 {
      &.zoom1 {
        background-image: url('https://eledo.online/assets/ck/bfc9d80f/ckeditor/plugins/dgcomponents/styles/pdf4171.png');
      }
      &.zoom2 {
        background-image: url('https://eledo.online/assets/ck/acaf6007/ckeditor/plugins/dgcomponents/styles/pdf4172.png');
      }
      &.zoom3 {
        background-image: url('https://eledo.online/assets/ck/76a76725/ckeditor/plugins/dgcomponents/styles/pdf4173.png');
      }
    }
    &.BC2_QR_CODE {
      &.zoom1 {
        background-image: url('https://eledo.online/assets/ck/7ef32569/ckeditor/plugins/dgcomponents/styles/qrcode1.png');
      }
      &.zoom2 {
        background-image: url('https://eledo.online/assets/ck/2c02a73f/ckeditor/plugins/dgcomponents/styles/qrcode2.png');
      }
      &.zoom3 {
        background-image: url('https://eledo.online/assets/ck/f14e286a/ckeditor/plugins/dgcomponents/styles/qrcode3.png');
      }
    }
  }
}
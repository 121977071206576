
.textareaLook {
  width: 100%;
  font-family: monospace;
  height: 5em;
}

.editor {
  font-size: 16pt;
  position: relative;
  width: 100%;
  min-height: 5em;
  padding: 8px 12px;
}

.editor > div,
.editor > textarea {
  font-size: 16pt;
  left: 0;
  right: 0;
  top: 0;
  min-height: 5em;
  position: absolute;
}


.cursorOverlayColors {
  color: transparent;
  background: transparent;
  caret-color: black;
  /* color: blue; */
  /* background: yellow; */
}

.tokensColors {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 8px 12px;
  line-height: 1.4;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin-bottom: 1em;
  overflow-y: scroll;
  /* pointer-events: none; */

}



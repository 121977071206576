@use "styles/variables" as *;


.dataGrid {
    -ms-grid-columns: 1fr .2fr .2fr 1fr 1fr 1fr;
    grid-template-columns: 1fr .2fr .2fr 1fr 1fr 1fr;

    .tileTitle {
        display: none;
    }
}

@media screen and (max-width: calc(#{$screen-size-s-max} + 150px)) {
    .dataGrid {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      border-bottom-color: $accent-gray;

      .tileTitle {
        display: block !important;
      }
    }
}

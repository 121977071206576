@use "styles/mixins" as m;

$grid-col-widths: 1.5fr .5fr .5fr 1fr;

.EledoInputFields {
  @include m.display-flex;
  @include m.flex-direction-column;

  .header-grid {
    -ms-grid-columns: $grid-col-widths;
    grid-template-columns: $grid-col-widths;
  }
  .data-grid {
    -ms-grid-columns: $grid-col-widths;
    grid-template-columns: $grid-col-widths;
  }
  .no-fields {
    margin: 1rem 0 3rem 0;
  }

  .section-small {
    padding-top: 0 !important;
  }

  .tree-layer .item {
    display: flex;
    flex-direction: row;
    line-height: 1.8;

    .title {
      flex-grow: 1;
    }
  }

  .tree-layer .item:hover {
    .title, .ext {
      background: #ddf !important;
      cursor: pointer;
    }
  }
}


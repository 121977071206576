@use 'styles/variables' as *;
@use "styles/mixins" as m;

.EledoUserProfile {
  height: 100vh;

  .TopToolbar {
    @include m.display-flex;
    @include m.flex-direction-row;
    @include m.flex-justify-space-between;
    @include m.flex-align-end;

    .ButtonDiv {
      button {
        margin-bottom: 5px;
      }

      button:last-of-type {
        margin-right: 0;
      }
    }

    @media screen and (max-width: $screen-size-s-max) {
      @include m.flex-direction-column;
      @include m.flex-justify-start;
      @include m.flex-align-start;

      .ButtonDiv {
        @include m.align-self-end;
        padding-top: 0;
      }

      h1 {
        margin-bottom: 0;
      }

      .my-section {
        padding-bottom: 0;
      }
    }

    @media screen and (max-width: $screen-size-l-max) {
      button {
        margin-right: 5px !important;
        margin-bottom: 5px !important;
        padding: 8px 16px !important;
      }
    }
  }

  h1 {
    white-space: nowrap;
  }

  button i, h1.best-points-heading {
    margin-right: 0.75rem;
  }
}

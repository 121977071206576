/*=========================================================
    VARIABLES - please use to make code more maintainable
 ==========================================================*/

//1rem = 16px

//z-indices
$left-menu-z-index: 800;
$modals-z-index: 999;
$nav-z-index: 20;
$backgrounds-z-index: -10;
$tabs-z-index: 2;
$dropdown-z-index: 10;

//navbar-left
$navbar-left-width-collapsed:     66px;
$navbar-left-width-uncollapsed:   260px;

//material-shadows
$mat-shadow-1: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$mat-shadow-2: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
$mat-shadow-3: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
$mat-shadow-4: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
$mat-shadow-5: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);

//shadows
$shadow-modal: 0 7px 26px 0 rgba(0, 80, 84, 0.13);
$shadow-nav: 0 1px 20px 0 rgba(0, 80, 84, 0.23);
$shadow-nav-left: 0 0 60px 10px rgba(0, 0, 0, 0.32);
$shadow-tabs: 0 10px 10px -5px rgba(0, 80, 84, 0.23);
$shadow-helpdesk: rgb(0 0 0 / 20%) 0px 7px 8px -4px, rgb(0 0 0 / 14%) 0px 12px 17px 2px, rgb(0 0 0 / 12%) 0px 5px 22px 4px;

//colors
$primary-green: #00ce2f;
$primary-green-active: #005054;
$primary-green-border: $primary-green;
$primary-green-vivid-pale: #4CE069;
$primary-green-pale: #A6EDB1;
$primary-white: #fff;
$primary-text: #333;
$focus-color: #005054;
$focus-color-lighter-60: rgba($focus-color, 0.6);
$focus-color-lighter-40: rgba($focus-color, 0.4);
$accent-yellow: #ffd400;
$accent-yellow-hover: darken($accent-yellow, 10%);
$toolbar-gray: #cfd1cf;
$accent-snow: #eaefef;
$accent-gray: #8ba5a4;
$accent-gray-lighter-20: lighten($accent-gray, 20%);
$accent-gray-lighter-25: lighten($accent-gray, 25%);
$accent-gray-dark: lighten($primary-text, 15%);
$accent-red: #e43d00;
$accent-red-hover: #852300;
$accent-red-light: lighten($accent-red, 53%);
$border-disabled: $accent-gray-dark;
$color-disabled: $accent-gray-dark;

// transitions
$transition-bezier: cubic-bezier(0.18, 0.89, 0.35, 1.15);
$transition-navbar: cubic-bezier(0, 0, 0.35, 1);
$transition-toggle: cubic-bezier(0, 1, 0.5, 1);
$transition-tabs: 100ms ease-in-out;

// media screen sizes (width)
$screen-size-xl-min: 1280px + $navbar-left-width-uncollapsed;
$screen-size-l-max: 1279px + $navbar-left-width-uncollapsed;
$screen-size-l-min: 992px + $navbar-left-width-uncollapsed;
$screen-size-m-max: 991px + $navbar-left-width-uncollapsed;
$screen-size-m-min: 768px + $navbar-left-width-uncollapsed;
$screen-size-s-max: 767px + $navbar-left-width-uncollapsed;
$screen-size-s-min: 480px + $navbar-left-width-uncollapsed;
$screen-size-xs-max: 479px + $navbar-left-width-uncollapsed;
@use 'styles/variables' as *;
@use "styles/mixins" as m;

.App {
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 100%;
}

.eledoContainer {
  padding: 0 0 0 $navbar-left-width-uncollapsed;
}

@media screen and (max-width: $screen-size-xs-max) {
  .eledoContainer {
    padding: 0 0 0 $navbar-left-width-collapsed;
  }
}

.eledoEditorContainer {
  padding-left: $navbar-left-width-collapsed;
}

button[aria-disabled],
html input[aria-disabled] {
  background-color: $primary-white !important;
  border-color: $border-disabled !important;
  color: $color-disabled !important;
  cursor: initial !important;
  &:hover, &:focus, &:active {
    background-color: $primary-white !important;
    border-color: $border-disabled !important;
  }
}

.mainContent {
  margin-top: 1rem;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.header-grid.no-top-margin {
  margin-top: 0;
}

.my-title-section {
  padding: 20px 50px;
}

.my-content-section {
  @extend .my-title-section;
  padding-bottom: 50px;
}

@media screen and (max-width: $screen-size-m-max) {
  .my-title-section.section.title {
    padding-top: 20px;
  }
}

.my-color-section {
  padding: 2rem;
}

.my-section {
  padding: 1rem 2rem;
}

.my-col-wrapper {
  margin-left: -10px;
  margin-right: -10px;
}

.my-exp-suggestions {
  display: block !important;
}

.vertical-scroll {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.cke_chrome {
  border-top: 0 !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

button.light-border {
  border: 1px solid $accent-gray-lighter-25 !important;
}

a.button-link {
  text-decoration: none;
  &:hover { text-decoration: underline; }
}

.flex-center {
  @include m.display-flex;
  @include m.flex-justify-center;
}
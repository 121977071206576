@use "styles/mixins" as m;
@use "styles/variables" as *;

.EledoInlineHelpdesk {
  position: fixed;
  bottom: 1.5rem;
  right: 1rem;
  z-index: 10;
  background-color: white;
  border-radius: 4px;
  opacity: 0.95;
  @include m.transition(all 0.25s !important);

  &:hover, &:focus, &:active {
    opacity: 1;
  }

  button {
    margin: 0 !important;
    box-shadow: $mat-shadow-4;
    @include m.transition(all 0.25s !important);

    &:hover {
      box-shadow: $mat-shadow-5;
    }

    &:active {
      box-shadow: $mat-shadow-3 !important;
    }
  }
}

.ButtonIcon {
  margin-right: 0.5rem;
  margin-left: -0.5rem;
}

.ModalTitle {
  //font-size: 15px;
}

.Modal {
  position: absolute;
  right: 1rem;
  bottom: 9rem;
  border: 1px solid #cccccc;
  box-shadow: $shadow-helpdesk;

  .TextArea {
    width: 100%;
    resize: none;
  }

  @media screen and (max-width: 530px) {
    right: 0;
  }
}
.ModalWrap {
  background-color: transparent;
}

.ModalActions {
  div {
    margin-top: 0 !important;
  }
}

.ButtonVisible {
  opacity: 1 !important;
}